<template>
  <container class="afisha_card" v-if="dataInfo">
    <div id="btnRegSticky" class="btnRegSticky" :class="{'active': isStickyReg}" v-if="data && !isFreeEntries && !isPaymentQRCode">
      <div class="btn_reg" ref="btnReg">
        <div class="disable-reg" v-if="!isActivePeriod() || (movies && Object.keys(movies).length && data.seances && data.seances.length == 0) || (data.seances?.length == 1 && !seanceTicketsAvailable) || (data.seances?.length > 1 && seanceGuid && !seanceTicketsAvailable) || (!data.seances && !eventTicketsAvailable)">
<!--          type="disable-background"-->
          <cl-button disabled class="btn-middle">{{ dataInfo.btnText.value }}</cl-button>
          <div class="div_text">{{ dataInfo.registrationClosed.value }}</div>
        </div>
        <div class="disable-reg" v-else-if="data.seances?.length > 1 && !seanceGuid">
          <cl-button class="btn-click btn-middle" @click="scrollCalendar()">{{ dataInfo.btnText1.value }}</cl-button>
          <div class="div_text">{{ dataInfo.selectSession.value }}</div>
        </div>
        <template v-else>
          <template v-if="!regBtnDisabled">
            <cl-button type="default" class="btn-middle" @click="v_ym(dataInfo.yandexMetrikaTargetReg?.value); showModalReg()">{{ dataInfo.btnText.value }}</cl-button>
          </template>
          <template v-else>
            <cl-button type="default" :disabled="regBtnDisabled" class="btn-middle">{{ dataInfo.btnText.value }}</cl-button>
          </template>
        </template>
      </div>
    </div>
    <row v-if="data" class="afisha_card_row">
      <column md="8">
        <div class="div_top">
          <h2 class="afisha_tit" v-if="data.title">{{ data.title }}</h2>
          <div id="btnRegFirstPos" class="btnRegFirstPos"></div>
          <div class="afisha_tags">
            <div class="div_tag" v-if="data.platform?.name">{{ data.platform.name }}</div>
            <div class="div_tag" v-for="(type, key) of data.types" :key="key">{{ type.name }}</div>
            <div class="div_tag div_tag1" v-if="data.ageLimit || data.ageLimit == 0">{{ data.ageLimit }}+</div>
          </div>
          <div v-if='(data.photos && data.photos.length > 0) || (data.video && data.video.length > 0)' class='sliderThumbs'>
            <swiper
                ref="mySwiperRent"
                :spaceBetween="10"
                :navigation="true"
                :thumbs="{ swiper: thumbsSwiper }"
                :threshold='10'
                :modules="modules"
                :loop="data.photos.length > 1 ? true : false"
                class="sliderThumbsMain"
                @slideChange="videoPause"
                @activeIndexChange="updateActiveIndex"
            >
              <template v-if="data.photos && data.photos.length > 0">
                <swiper-slide v-for="(photo, index) in data.photos" :key="photo.guid">
                  <div class="img_wrap_rent" @click="showModalGalFun(data.photos, index)">
                    <img :src="photo.imageUrl" alt="" />
                  </div>
                </swiper-slide>
              </template>
              <template v-if="data.video && data.video.length > 0">
                <swiper-slide v-for="itemVideo of data.video" :key="itemVideo" class="swiper-video-slide">
                  <loader
                      v-if="isLoadingVideo"
                      :animation-duration="3000"
                      :size="60"
                  ></loader>
                  <div class="swiper-video" v-else-if="itemVideo.iframe" v-html="itemVideo.iframe"></div>
                </swiper-slide>
              </template>
            </swiper>
            <swiper
                ref="thumbsSwiper"
                @swiper="setThumbsSwiper"
                :spaceBetween="24"
                :freeMode="true"
                :slidesPerView="'4'"
                :class="{'swiper_left': data.photos.length > 4}"
                :watchSlidesProgress="true"
                :threshold='10'
                :modules="modules"
                class="sliderThumbsThumbs hideMob"
                :initialSlide="activeIndex"
                @slideChange="updateMainSwiper"
            >
              <template v-if="data.photos && data.photos.length > 1">
                <swiper-slide v-for="(photo, index) of data.photos" :key="photo.guid" :class="{ slide_new: index === realIndex }">
                  <img :src="photo.imageUrl" alt=""/>
                </swiper-slide>
              </template>
              <template v-if="data.video && data.video.length > 0">
                <swiper-slide v-for="(itemVideo, index) of data.video" :key="itemVideo" class="swiper-thumb-video" :class="{ slide_new: index === realIndex }">
                  <loader
                      v-if="isLoadingVideo"
                      :animation-duration="3000"
                      :size="60"
                  ></loader>
                  <img v-else-if="itemVideo.preview" :src="itemVideo.preview" alt=""/>
                </swiper-slide>
              </template>
            </swiper>
            <modal-gallery
                :dataGallery="dataGallery"
                :initialSlide="initialSlide"
                :modelValue="showModalSlide"
                :thumbsGal="true"
            />
          </div>

          <div id="infoAside"></div>
        </div>
        <div class="div_description">
          <h3 v-if="data.subtitle">{{ data.subtitle }}</h3>
          <div v-if="data.description" v-html="data.description"></div>
        </div>
        <div class="afisha_schedule" v-if="data.programs">
          <h3 class="div_tit">{{ isTypeExcursionsMoskino ? dataInfo.eventProgramExcursions.value : dataInfo.eventProgram.value }}</h3>
          <div class="div_items">
            <div class="item" v-for="(program, key) of data.programs" :key="key">
              <div class="dt1" v-if="!isTypeExcursionsMoskino">
                <template v-if="program.startTime">
                  {{ getCurrentTimeFormattedNew(program.startTime) }}
                </template>
                <template v-if="program.startTime && program.endTime">-</template>
                <template v-if="program.endTime">
                  {{ getCurrentTimeFormattedNew(program.endTime) }}
                </template>
              </div>
              <div class="dt2">
                <div class="dot"></div>
                <div class="line"></div>
              </div>
              <div class="dt3">
                <cl-collapse class="collapse_schedule">
                  <template v-slot:name>
                    <template v-if="program.header">{{ program.header }}</template>
                  </template>
                  <template v-slot:card>
                    <div v-if="program.description" v-html="program.description"></div>
                  </template>
                </cl-collapse>
              </div>
            </div>
          </div>
        </div>
        <template v-if="movies && Object.keys(movies).length > 0">
          <h3 class="d-none d-lg-block" v-if="isTypeFilmShowing">{{ Object.keys(movies).length == 1 ? dataInfo.textFilm.value : dataInfo.textFilms.value }}</h3>
          <div class="about_film_list">
            <template v-for="(movie, key) in movies" :key="key">
              <div class="about_film" v-if="movie">
                <div class="dt1" v-if="isTypeFilmShowing">
                  <div class="dt2">
                    <h4 v-if="movie?.name" class="film_tit">{{ movie.name }}
                      <span class="film_tag" v-if="movie?.ageRestriction">{{ movie.ageRestriction }}+</span>
                    </h4>
                  </div>
                  <row class="gallery" v-if="movie?.photos">
                    <template v-for="(item, key) of getGallery(movie.photos)" :key="key">
                      <column :md="key == 0 ? 12 : 3" :lg="key == 0 ? 12 : 6" v-if="(key < 5 && widthSite > 991) || (key < 3 && widthSite < 768)">
                        <div class="img_gal end" v-if="(key == 4 && movie.photos?.length > 5 && widthSite > 991) || (key == 2 && movie.photos?.length > 2 && widthSite < 768)" @click="openModalGal(getGallery(movie.photos), key)">
                          <img :src="item" alt="">
                          <div class="num">+{{ widthSite < 768 ? movie.photos.length - 3 : movie.photos.length - 5 }}</div>
                        </div>
                        <div class="img_gal" :class="{'start': key == 0}" v-else-if="key < 5" @click="openModalGal(getGallery(movie.photos), key)">
                          <img :src="item" alt="">
                        </div>
                      </column>
                    </template>
                  </row>
                </div>
                <div class="dt2 dt2_mob">
                  <template v-if="isTypeFilmShowing">
                    <h4 v-if="movie?.name" class="film_tit">{{ movie.name }}
                      <span class="film_tag" v-if="movie?.ageRestriction">{{ movie.ageRestriction }}+</span>
                    </h4>
                    <div class="div_text" v-if="movie?.description" v-html="movie.description"></div>
                    <div class="features p_16">
                      <row v-if="isTypeExcursionsMoskino">
                        <column v-if="movie?.duration">
                          <div class="name">{{ dataInfo.timeExcursions.value }}</div>
                          <div class="value">{{ movie.duration }} мин.</div>
                        </column>
                      </row>
                      <row v-else>
                        <column md="4" v-if="movie?.director?.length">
                          <div class="name">{{ dataInfo.director.value }}</div>
                          <div class="value">
                            <template v-for="(director, key) of movie.director" :key="key">
                              {{ director }}<span v-if="key != movie.director?.length - 1">, </span>
                            </template>
                          </div>
                        </column>
                        <column md="2" v-if="movie?.duration">
                          <div class="name">{{ dataInfo.time.value }}</div>
                          <div class="value">{{ movie.duration }} мин.</div>
                        </column>
                        <column md="3" v-if="movie?.country?.length">
                          <div class="name">{{ dataInfo.country.value }}</div>
                          <div class="value">
                            <template v-for="(country, key) of movie.country" :key="key">
                              {{ country.name }}<span v-if="key != movie.country?.length - 1">, </span>
                            </template>
                          </div>
                        </column>
                        <column md="3" v-if="movie.year">
                          <div class="name">{{ dataInfo.productionYear.value }}</div>
                          <div class="value">{{ movie.year }}</div>
                        </column>
                        <!--                  <column sm="3" v-if="movie?.year">-->
                        <!--                    <div class="name">{{ dataInfo.premiere.value }}</div>-->
                        <!--                    <div class="value">{{ movie.year }}</div>-->
                        <!--                  </column>-->
                        <!--                  <column sm="3" v-if="movie?.posterRoles?.length">-->
                        <!--                    <div class="name">{{ dataInfo.cast.value }}</div>-->
                        <!--                    <div class="value">-->
                        <!--                      <template v-for="(role, key) of movie.posterRoles" :key="key">-->
                        <!--                        {{ role }}<span v-if="key != movie.posterRoles?.length - 1">, </span>-->
                        <!--                      </template>-->
                        <!--                    </div>-->
                        <!--                  </column>-->
                      </row>
                    </div>
                  </template>
                  <div class="schedule_events" v-if="data.seances.length > 1" ref="scheduleEvent">
                    <!--                <h4 class="title">{{ dataInfo.schedule.value }}</h4>-->
                    <!--              Это регулярное выражение оставляет в строке только буквы и цифры - /[^\p{L}\p{N}]/gu-->
                    <calendar-carousel
                        class="col-66 afisha_calendar"
                        @change="changeDay(movie.guid, key, $event)"
                        :period="false"
                        :activeDates="getDays(data.date[key])"
                        :chooseFirstActive="true"
                        :firstActiveDate="true"
                        :indexCalendar="key.replace(/[^\p{L}\p{N}]/gu, '')"
                    />
                    <h4 class="subtitle" v-if="movie.cinemas && Object.keys(movie.cinemas).length == 1">
                      {{ dataInfo.venues1.value }}
                    </h4>
                    <h4 class="subtitle" v-else>{{ dataInfo.venues2.value }}</h4>
                    <h5 class="not_events_info" v-if="movie?.cinemas?.length == 0">
                      На выбранную дату сеансов нет
                    </h5>
                    <div class="cards_event" v-else-if="movie?.cinemas" v-for="(cinema, key) in movie.cinemas" :key="key">
                      <div class="card_event">
                        <div class="dt1">
                          <div class="logo" v-if="cinema.logo?.previewUrl">
                            <img :src="cinema.logo.previewUrl" alt="">
                          </div>
                        </div>
                        <div class="dt2">
                          <h5  class="div_tit" v-if="cinema?.name">{{ cinema.name }}</h5>
                          <div class="div_desc p_16" v-if="cinema.address">{{ cinema.address }}</div>
                          <div class="list_time">
                            <template v-for="(seance, key) in cinema.seanceInfo" :key="key">
                              <div
                                  class="item"
                                  @click="v_ym(dataInfo.yandexMetrikaTargetTime?.value); setFormParamsSeance(seance, key); checkTicketsAvailable(seance, key)"
                                  v-if="seance?.timeStart"
                                  :class="{
                              'active': seanceGuid == seance.guid,
                              'not_active_seance': !isActiveSeance(seance),
                              'not_active_seance2': this.isFreeEntries || this.isPaymentQRCode
                            }"
                              >
                                {{ seance.timeStart.split(":")[0] }}:{{ seance.timeStart.split(":")[1] }}
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <loader size="60" v-else></loader>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>

        <div class="speakers" v-if="data.speakers?.length">
          <h4 class="div_tit">
            {{ dataInfo.speakers.value }}
          </h4>
          <row>
            <column md="6" v-for="(speaker, key) in data.speakers">
              <div class="item">
                <div class="div_img">
                  <img :src="speaker.photo?.imageUrl" alt="" v-if="speaker.photo?.imageUrl">
                  <div class="not_img" v-else>{{speaker.lastName[0]}}{{speaker.firstName[0]}}</div>
                </div>
                <div class="div_info">
                  <div class="div_name">{{ speaker.lastName }} {{ speaker.firstName }}</div>
                  <div class="div_desc">{{ speaker.position }}</div>
                </div>
              </div>
            </column>
          </row>
        </div>
        <div id="partnersSndOrga" class="partnersSndOrga"></div>
      </column>
      <column md="4">
        <teleport to="#infoAside" :disabled="widthSite > 767" v-if="isMountedComponent">
          <div class="afisha_aside">
            <div class="item_property" v-if="data.eventStartDate">
              <div class="property_title clW20">{{ dataInfo.when.value }}</div>
              <div class="property_value">{{ getWhen() }}</div>
            </div>
            <div class="item_property">
              <div class="property_title clW20">{{ dataInfo.entrance.value }}</div>
              <div class="property_value">
                <template v-if="data.seanceEntry && data.seanceEntry.id === '1325602' && data.priceFrom">{{ data.priceFrom }} <template v-if="data.priceto && data.priceFrom != data.priceto">- {{ data.priceto }}</template> руб.</template>
                <template v-else-if="isFreeEntries && dataInfo.textFreeEntries">{{ dataInfo.textFreeEntries.value }}</template>
                <template v-else-if="isPaymentQRCode && dataInfo.textPaymentQRCode">{{ dataInfo.textPaymentQRCode.value }}</template>
                <template v-else>{{ dataInfo.byRegistration.value }}</template>
              </div>
            </div>
            <div class="item_property">
              <div class="property_title clW20">{{ dataInfo.address.value }}</div>
              <template v-if="data.regEventVenues">
                <div class="property_value" v-if="data.regEventVenues?.length == 1">
                  {{ data.regEventVenues[0].name }} <br>
                  {{ data.regEventVenues[0].address }}
                </div>
                <div class="property_value" v-else-if="data.regEventVenues?.length > 1">
                  {{ data.regEventVenues.length }} {{ getPluralForm(data.regEventVenues.length, 'площадка', 'площадки', 'площадок') }}
                </div>
              </template>
              <cl-collapse class="collapse_map" v-if="data.regEventVenues?.length == 1" :visibleStart="true" @collapsed="collapseMap">
                <template v-slot:name>
                  <template v-if="isShowMap">
                    Скрыть карту
                  </template>
                  <template v-else>
                    Показать карту
                  </template>
                </template>
                <template v-slot:card>
                  <div class="ya-map-container">
                    <div class="map_btn">
                      <div
                          :class="{'is-disabled': zoom > 20}"
                          class="map_btnPlus"
                          @click="mapIncrease">
                      </div>
                      <div
                          :class="{'is-disabled': zoom < 2}"
                          class="map_btnMinus"
                          @click="mapDecrease">
                      </div>
                    </div>
                    <yandex-map
                        @map-was-initialized="mapLoaded"
                        :settings="settings"
                        :coords="[data.regEventVenues[0].lat, data.regEventVenues[0].lon]"
                        :controls="[]"
                        :zoom="zoom"
                        class="ya-map-afisha"
                    >
                      <ymap-marker
                          :coords="[data.regEventVenues[0].lat, data.regEventVenues[0].lon]"
                          :icon="markerIcon"
                          :options="markerOptions"
                      >
                      </ymap-marker>
                    </yandex-map>
                  </div>
                </template>
              </cl-collapse>
            </div>
            <teleport to="#btnRegFirstPos" :disabled="widthSite > 767" v-if="isMountedComponent && !isFreeEntries && !isPaymentQRCode">
              <div class="btn_reg" ref="btnReg">
                <div class="disable-reg" v-if="!isActivePeriod() || (movies && Object.keys(movies).length && data.seances && data.seances.length == 0) || (data.seances?.length == 1 && !seanceTicketsAvailable) || (data.seances?.length > 1 && seanceGuid && !seanceTicketsAvailable) || (!data.seances && !eventTicketsAvailable)">
<!--                  type="disable-background"-->
                  <cl-button disabled class="btn-middle">{{ dataInfo.btnText.value }}</cl-button>
                  <div class="div_text">{{ dataInfo.registrationClosed.value }}</div>
                </div>
                <div class="disable-reg" v-else-if="data.seances?.length > 1 && !seanceGuid">
                  <cl-button class="btn-click btn-middle" @click="scrollCalendar()">{{ dataInfo.btnText1.value }}</cl-button>
                  <div class="div_text">{{ dataInfo.selectSession.value }}</div>
                </div>
                <template v-else>
                  <template v-if="!regBtnDisabled">
                    <cl-button type="default" class="btn-middle" @click="v_ym(dataInfo.yandexMetrikaTargetReg?.value); showModalReg()">{{ dataInfo.btnText.value }}</cl-button>
                  </template>
                  <template v-else>
                    <cl-button type="default" :disabled="regBtnDisabled" class="btn-middle">{{ dataInfo.btnText.value }}</cl-button>
                  </template>
                </template>
              </div>
            </teleport>
            <teleport to="#partnersSndOrga" :disabled="widthSite > 767" v-if="isMountedComponent">
              <div class="partners">
                <h4 class="div_tit clW20">{{ dataInfo.organizers.value }}</h4>
                <div class="items">
                  <div class="item" v-for="company in data.companies">
                    <div class="div_img" v-if="company.logo?.previewUrl">
                      <img :src="company.logo.previewUrl" alt="">
                    </div>
                    <template v-if="company.name">
                      {{ company.name }}
                    </template>
                  </div>
                </div>
              </div>
              <div class="partners" v-if="data.partners?.length">
                <h4 class="div_tit clW20">{{ dataInfo.partners.value }}</h4>
                <div class="items">
                  <div class="item" v-for="(partner, key) of data.partners">
                    <div class="div_img" v-if="partner.logo?.previewUrl">
                      <img :src="partner.logo.previewUrl" alt="">
                    </div>
                    <template v-if="partner.company?.name">
                      {{ partner.company.name }}
                    </template>
                  </div>
                </div>
              </div>
            </teleport>
          </div>
        </teleport>
      </column>
    </row>
    <loader size="60" v-else></loader>
    <modal-gallery :dataGallery="gallery" :initialSlide="initialSlide" :modelValue="showModalGal" :thumbsGal="true"/>
    <cl-modal v-if="isTypeExcursionsMoskino" class="modal-iframe-excursions" v-model="isShowModalReg" :closeStandart="true">
      <iframe class="iframe-excursions" ref="iframeTypeExcursionsMoskino" :src="'https://online.kinopark.moscow/basketiframe?name=' + externalCodeIframe"
              v-if="externalCodeIframe"
      />
      <div v-else class="color-black">Нету данных</div>
    </cl-modal>
    <cl-modal v-else modal-type="black" class="modal-afisha" v-model="isShowModalReg" :closeStandart="true">
      <template v-slot:header>
        {{ dataInfo.registration.value }}
      </template>
      <template v-slot:body>
        <measure-form-component
            v-if="data.measure"
            :measureGuid="data.measure"
            :showMode="showMode"
            :formParams="formParams"
            :successText="dataInfo.formSucces.value"
            :successTextImportant="true"
        />
<!--        Регистрация на мероприятие прошла успешно. Ваш билет отправлен на почту-->
      </template>
    </cl-modal>
  </container>
</template>

<script>
import ClCollapse from "@/components/library/ClCollapse.vue";
import Row from "@/components/library/Row.vue";
import Column from "@/components/library/Column.vue";
import ModalGallery from "@/components/modalGallery.vue";
import ClButton from "@/components/library/ClButton.vue";
import Loader from "@/components/library/Loader.vue";
import CalendarCarousel from "@/components/calendarCarousel.vue";
import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps'
import ClModal from "@/components/library/ClModal.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {FreeMode, Navigation, Thumbs} from "swiper";

let mapInstance;

export default {
  name: 'afishaCard',
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  computed: {
    types() {
      return types
    },
    ...mapGetters([
      'widthSite'
    ])
  },
  components: {
    MeasureFormComponent,
    ClModal,
    CalendarCarousel,
    Loader,
    ClButton,
    ModalGallery,
    Column,
    Row,
    ClCollapse,
    yandexMap,
    ymapMarker,
    Swiper,
    SwiperSlide
  },
  mixins:[mixinModalAlertMeasureLeave],
  data() {
    return {
      tempImg: [],
      dataGallery: null,
      activeIndex: 0,
      realIndex: 0,
      thumbsSwiper: null,
      idTemp2: "dc6fecaf01ee49459562a486c7d39d07",
      id: null,
      idTemp: "7af25ff97f414c49aa8c374ced08e334",
      isMountedComponent: false,
      isStickyReg: false,
      data: null,
      initialSlide: 0,
      showModalSlide: false,
      showModalGal: false,
      gallery: null,
      movies: null,
      regBtnDisabled: false,
      settings: {
        apiKey: 'c99b23cc-9919-4a5f-b713-5653ee3e0cd5',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      coords: [
        55.75692599166862, 37.61779536976927
      ],
      zoom: 14,
      markerOptions: {
        balloonPanelMaxMapArea: Infinity,
      },
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: '/img/ico/marker.svg',
        imageSize: [32, 32],
        imageOffset: [-32, -32],
        contentOffset: [-32, -32],
      },
      isShowModalReg: false,
      seanceGuid: null,
      showMode: 'inline',
      formParams: {
        'appendCss': '/override/events.css',
        preSetParams: {
          widget: "mcounter",
        }
      },
      isShowMap: false,
      controller: null,
      seanceTicketsAvailable: true,
      eventTicketsAvailable: true,

      isTypeExcursionsMoskino: false,
      externalCodeIframe: null,

      isFreeEntries: false,
      isPaymentQRCode: false,
      /**
       * категория КИНОПОКАЗ
       * @type {boolean}
       * @default false
       */
      isTypeFilmShowing: false,
    }
  },
  methods: {
    /**
     * Функция для открытия и инициализации
     * модальной галереи
     * @param gallery - массив фото
     * @param index - индекс элемента внутри массива фото
     */
    showModalGalFun(gallery, index) {
      this.tempImg = [];
      gallery.forEach(elem => { this.tempImg.push(elem.imageUrl)});
      this.dataGallery = this.tempImg;
      this.initialSlide = index;
      this.showModalSlide = true;
    },
    /**
     * Функция присваивает thumbsSwiper номер
     * @param swiperInstance - номер слайда
     */
    setThumbsSwiper(swiperInstance) {
      this.thumbsSwiper = swiperInstance;
    },
    /**
     * Функция обновляет индекс активного слайда thumbsSwiper
     * @param swiperInstance - номер слайда
     */
    updateActiveIndex(swiperInstance) {
      this.realIndex = swiperInstance.realIndex;
      this.activeIndex = swiperInstance.activeIndex;
    },
    /**
     * Функция обновляет главный слайдер на основе изменений,
     * происходящих во вспомогательном слайдере
     * @param swiperInstance - номер слайда
     */
    updateMainSwiper(swiperInstance) {
      if (this.$refs.mySwiperRent && this.$refs.mySwiperRent.swiper) {
        this.$refs.mySwiperRent.swiper.slideTo(swiperInstance.realIndex);
      }
    },
    openModalGal(gallery, index) {
      this.gallery = gallery;
      this.initialSlide = index;
      this.showModalGal = true;
    },
    /**
     * Формирует строку времени в виде - hh:mm.
     * @param date - Дата в виде строки.
     * @returns {string} - Сформированная строка времени.
     */
    getCurrentTimeFormatted(date) {
      const dateTime = new Date(date);
      const hours = dateTime.getHours().toString().padStart(2, "0");
      const minutes = dateTime.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    /**
     * Формирует строку времени в виде - hh:mm.
     * @param date - Дата в виде строки по типу чч:мм:сс.
     * @returns {string} - Сформированная строка времени.
     */
    getCurrentTimeFormattedNew(date) {
      const time = date.split(':');
      return `${time[0]}:${time[1]}`;
    },
    /**
     * Собирает массив ссылок на изображения.
     * @param photos - Массив изображений из РПП.
     * @returns {string[]} - Массив ссылок на изображения.
     */
    getGallery(photos) {
      if (photos?.length) {
        const gallery = [];
        photos.forEach(photo => {
          if (photo.imageUrl) {
            gallery.push(photo.imageUrl);
          }
        });
        return gallery;
      }
    },
    collapseMap(value) {
      this.isShowMap = value;
    },
    changeDay(guid, name, day){
      this.movies[name].cinemas = null;
      this.axios.get("/api/data/cinema-seances", {
        params: {
          movies: guid,
          date: this.formatDate(day.start, "dd.MM.yyyy"),
          eventGuid: this.id,
        },
        signal: this.controller.signal
      })
          .then(response => {
            if (response.data?.[name]?.cinemas) {
              this.movies[name].cinemas = response.data[name].cinemas;
            } else {
              this.movies[name].cinemas = [];
            }
          })
          .catch(error => {
            this.showError(error);
          });
    },
    getDays(daysArr) {
      const days = [];
      if (daysArr) {
        for (let key in daysArr) {
          days.push(new Date(daysArr[key]));
        }
      }
      return days;
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
    getWhen() {
      let str = "";
      let optionDayStart = {day: 'numeric', month: 'long'};


      if (this.data.eventStartDate) {
        const StartDate = new Date(this.data.eventStartDate);
        const EndDate = new Date(this.data.eventEndDate);
        if (this.data.eventEndDate) {
          str += "С ";
          if(StartDate.getMonth() == EndDate.getMonth()){
            optionDayStart = {day: 'numeric'};
            if(StartDate.getDate() == EndDate.getDate()){
              optionDayStart = {};
            }
          }
          if(StartDate.getFullYear() != EndDate.getFullYear()){
            optionDayStart = {day: 'numeric', month: 'long', year: 'numeric'};
          }
        }
        if(Object.keys(optionDayStart).length){
          str += StartDate.toLocaleDateString('ru-RU', optionDayStart);
        } else {
          str = "";
        }

        if (this.data.eventEndDate) {
          if(Object.keys(optionDayStart).length > 0){
            str += " по ";
            str += EndDate.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long', year: 'numeric'});
          } else {
            str += EndDate.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long', year: 'numeric'}).replace(' г.', ', ');
            if(this.data.startTime){
              str +=  this.data.startTime.split(":")[0] + ':' + this.data.startTime.split(":")[1];
            }
          }
        } else if(this.data.startTime) {
          str += " " + this.data.startTime.split(":")[0] + ":" + this.data.startTime.split(":")[1];
        }
      }
      return str;
    },
    mapIncrease() {
      if (this.zoom === mapInstance.getZoom()) {
        this.zoom++
      } else {
        this.zoom = mapInstance.getZoom() + 1;
      }
    },
    mapDecrease() {
      if (this.zoom === mapInstance.getZoom()) {
        this.zoom--
      } else {
        this.zoom = mapInstance.getZoom() - 1;
        mapInstance.setZoom(mapInstance.getZoom() - 1);
      }
    },
    mapLoaded(myMap) {
      mapInstance = myMap;
    },
    getPluralForm(number, one, two, many) {
      if (number % 10 === 1 && number % 100 !== 11) {
        return one;
      } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
        return two;
      } else {
        return many;
      }
    },
    isActivePeriod() {
      if (this.data && this.data.applicRecEndDate) {
        const end = new Date(this.data.applicRecEndDate);
        if (this.data.endTime) {
          const splitEndTime = this.data.endTime.split(":");
          end.setHours(splitEndTime[0], splitEndTime[1], splitEndTime[2]);
        }
        const dateNow = new Date();

        return dateNow <= end;
      }
    },
    setFormParamsSeance(seanceInfo, openModal = true) {
      if(this.isTypeExcursionsMoskino){
        this.externalCodeIframe = seanceInfo.externalCode;
        if (this.isActivePeriod() && seanceInfo.date && seanceInfo.timeStart && !(this.externalCodeIframe.indexOf('&time=') != -1 && this.externalCodeIframe.indexOf('&date=') != -1)) {
          const date = new Date(seanceInfo.date);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = String(date.getFullYear()).slice(-2);
          this.externalCodeIframe = seanceInfo.externalCode + '&date=' + day + '.' + month + '.' + year + '&time=' + seanceInfo.timeStart.split(":")[0] + ":" + seanceInfo.timeStart.split(":")[1]
        }
      }
      if (this.isActivePeriod()) {
        if (seanceInfo.date && seanceInfo.timeStart) {
          let str = "";
          str += new Date(seanceInfo.date).toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'});
          str += " " + seanceInfo.timeStart.split(":")[0] + ":" + seanceInfo.timeStart.split(":")[1];

          this.formParams.preSetParams["eventdate"] = str;
        }
        if (seanceInfo.address) {
          this.formParams.preSetParams["eventaddress"] = seanceInfo.address;
        } else {
          if (seanceInfo.regEventVenues && seanceInfo.regEventVenues !== undefined){
            this.formParams.preSetParams["eventaddress"] = seanceInfo.regEventVenues[0].address
          }
        }
        if (seanceInfo.movie?.name) {
          this.formParams.preSetParams["filmname"] = seanceInfo.movie.name;
        }
        if (seanceInfo.guid) {
          this.seanceGuid = seanceInfo.guid;
          this.formParams.preSetParams["cinemaseanceguid"] = seanceInfo.guid;
        }
        if (seanceInfo.videoFormat?.name) {
          this.formParams.preSetParams["cinemaformatvideo"] = seanceInfo.videoFormat.name;
        }
        if (seanceInfo.soundFormat?.name) {
          this.formParams.preSetParams["cinemaformataudio"] = seanceInfo.soundFormat.name;
        }
        if (seanceInfo.location.address) {
          this.formParams.preSetParams["eventaddress"] = seanceInfo.location.address;
        }
        if (seanceInfo.location.name) {
          this.formParams.preSetParams["nameeventplace"] = seanceInfo.location.name;
        }

        if(openModal){
          this.showModalReg();
        }
      }
    },
    scrollCalendar(){
      if (this.$refs.scheduleEvent?.length) {
        window.scrollTo({top: this.$refs.scheduleEvent[0].getBoundingClientRect().top + window.scrollY, behavior: 'smooth'});
      } else if (this.$refs.scheduleEvent) {
        window.scrollTo({top: this.$refs.scheduleEvent.getBoundingClientRect().top + window.scrollY, behavior: 'smooth'});
      }
    },
    showModalReg() {
      this.isShowModalReg = true;

      // if(this.isTypeExcursionsMoskino){
      //   this.$nextTick(() => {
      //     let element = this.$refs.iframeTypeExcursionsMoskino;
      //     setInterval(() => {
      //       if (!element && !element.contentWindow && !element.contentWindow.document.body) {
      //         return;
      //       }
      //       element.height = element.contentWindow.document.body.scrollHeight + 30;
      //     }, 200);
      //   });
      // }

      if (this.data.seances?.length == 1 && !this.seanceGuid) {
        const seance = this.data.seances[0];
        this.setFormParamsSeance(seance, false);
      }
    },
    isActiveSeance(seance) {
      if (seance?.date && seance.timeStart) {
        const seanceDate = new Date(seance.date);
        const timeSplit = seance.timeStart.split(":");
        seanceDate.setHours(timeSplit[0], timeSplit[1], timeSplit[2]);

        return new Date() <= seanceDate;
      }
    },
    hiddenRegs() {
      if (window.innerWidth < 767) {
        setTimeout(() => {
          const k = document.querySelector('.afisha_card_row')
          const block1 = document.querySelector('.afisha_card_row .div_img')
          const block2 = document.querySelector('.afisha_card_row .afisha_tit')

          var blockHeight = k.offsetHeight - block2.offsetHeight - block1.offsetHeight - 100;
          var screenHeight = window.innerHeight;

          if (blockHeight <= screenHeight && document.querySelector('.btnRegSticky')) {
            document.querySelector('.btnRegSticky').style.visibility = 'hidden';
          }
        }, 500)
      }
    },
    checkTicketsAvailable(seanceInfo) {
      this.seanceTicketsAvailable = seanceInfo.countTickets > 0;
    }
  },
  setup() {
    return {
      modules: [FreeMode, Navigation, Thumbs]
    }
  },
  mounted() {
    if (this.$refs.thumbsSwiper) {
      this.thumbsSwiper = this.$refs.thumbsSwiper.swiper;
    }
    const pathArr = window.location.pathname.split("/");
    this.id = pathArr[pathArr.length - 1];

    // Данные мероприятия
    this.axios.get("/api/data/cinema-events", {
      params: {
        id: this.id,
      },
      signal: this.controller.signal
    })
        .then(response => {
          this.data = response.data;
          this.formParams.preSetParams["eventname"] = this.data.title;
          this.formParams.preSetParams["cinemaeventguid"] = this.id;
          this.formParams.preSetParams["eventdate"] = this.getWhen();
          this.formParams.preSetParams["typeorprice"] = "По регистрации";

          if (this.data.regEventVenues !== null && this.data.regEventVenues.length > 0){
            this.formParams.preSetParams["eventaddress"] = this.data.regEventVenues[0].address;
          };

          if (this.data.applicRecStartDate && this.data.applicRecStartDate !== null){
            let currentDate = new Date();
            let dateCompare = new Date(this.data.applicRecStartDate);
            if (currentDate < dateCompare){
              this.regBtnDisabled = true;
            }
          }

          if (this.data.seances && this.data.seances !== undefined){
            this.formParams.preSetParams["eventaddress"] = this.data.seances[0].address;
          } else {
            if (this.data.regEventVenues.length && this.data.regEventVenues !== undefined){
              this.formParams.preSetParams["eventaddress"] = this.data.regEventVenues[0].address;
            }
          }
          if (this.data.seances && this.data.seances !== undefined){
            this.formParams.preSetParams["nameeventplace"] = this.data.seances[0].name;
          } else {
            if (this.data.regEventVenues && this.data.regEventVenues !== undefined){
              this.formParams.preSetParams["nameeventplace"] = this.data.regEventVenues[0].name;
            }
          }


          if (this.data.applicRecStartDate && this.data.applicRecStartDate !== undefined){
            let currentDate = new Date();
            let dateCompare = new Date(this.data.applicRecStartDate);
            if (currentDate < dateCompare){
              this.regBtnDisabled = true;
            }
          }

          this.$nextTick(() => {
            this.isMountedComponent = true;

            helperApp.addBreadcrumbsLink(this.data.title, 'breadcrumbs4');

            this.$nextTick(() => {
              let footerActiveObserver = false;
              const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                  if (!entry.isIntersecting) {
                    if (!footerActiveObserver) {
                      footerActiveObserver = true;
                      const footer = document.querySelector("footer");
                      if (footer) {
                        observer.observe(footer);
                      }
                    }
                    this.isStickyReg = true;
                  } else {
                    this.isStickyReg = false;
                  }
                });
              }, {
                root: null, // Отслеживание в viewport
                threshold: [0, 1] // Вызывается, когда 100% кнопки видимы
              });
              if(this.$refs.btnReg){
                observer.observe(this.$refs.btnReg);
              }
            });
          });

          // Функция скрытия всплывающего блока регистрации для высоких экранов мобилок
          this.hiddenRegs()

          //проверка на экскурсии и Кинопарк “Москино” и вход платный (seanceEntry.id === '1325602')
          if(this.data.types && this.data.types.length && this.data.platform && this.data.seanceEntry && this.data.seanceEntry.id === '1325602'){
            this.isTypeExcursionsMoskino = this.data.types.some(item => item.id == "1323902") && this.data.platform.id == "1324003";
          }

          //проверка на свободный вход (seanceEntry.id === '1325603')
          if(this.data.seanceEntry && this.data.seanceEntry.id === '1325603'){
            this.isFreeEntries = true;
          }

          //проверка Оплата по QR-коду на месте (seanceEntry.id === '1325604')
          if(this.data.seanceEntry && this.data.seanceEntry.id === '1325604'){
            this.isPaymentQRCode = true;
          }

          // Проверка на наличие билетов мероприятия

          if(this.data.countTickets || this.data.countTickets === 0) {
            this.eventTicketsAvailable = this.data.countTickets > 0;
          }

          if(this.data.types && this.data.types.length && this.data.types.some(item => item.id === "1323901")){
            this.isTypeFilmShowing = true;
          }

        })
        .catch(error => {
          this.showError(error);
        });
    this.axios.get("/api/data/cinema-seances", {
      params: {
        eventGuid: this.id,
        isDate: true,
      },
      signal: this.controller.signal
    })
        .then(response => {
          this.movies = response.data;
          if (this.movies && Object.keys(this.movies).length) {
            const cinemas = this.movies[Object.keys(this.movies)[0]].cinemas;

            const seanceInfo = Object.values(cinemas)[0].seanceInfo;
            const tickets = Object.values(seanceInfo)[0].countTickets;

            this.seanceTicketsAvailable = tickets > 0;

            if (Object.keys(cinemas).length) {
              this.formParams.preSetParams["eventaddress"] = cinemas[Object.keys(cinemas)[0]].address;
            }
          }
        })
        .catch(error => {
          this.showError(error);
        });


    // скрываем модалку если выскочило сообшение об отправке
    const targetNode = document.getElementById("modal-form-success");
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        this.isShowModalReg = false;
      }
    });
    const config = { attributes: true };
    observer.observe(targetNode, config);

  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  }
};
</script>

<style lang="scss" scoped>

.sliderThumbs {
  padding: 20px 0;
  &Thumbs {
    .swiper-slide {

      &.swiper-slide-thumb-active img {
        border: none;
      }

      &.slide_new img {
        border: 2px solid #ED850A;
      }
    }
  }
}
.afisha_card {
  padding-top: 164px;
  .div_img {
    margin-bottom: 20px;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  h2, h3, h4 {
    text-transform: uppercase;
  }
  h3, h4 {
    margin-bottom: 16px;
  }
  .div_description {
    font-size: 1.6rem;
    line-height: 150%;
    margin-bottom: 32px;
  }
  .p_16 {
    font-size: 1.6rem;
  }
  .afisha_tit {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
  .afisha_tags {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div_tag {
      border: 1px solid rgb(46, 49, 56);
      border-radius: 30px;
      padding: 12px 16px 12px 18px;
      &.div_tag1 {
        width: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .afisha_schedule {
    margin-bottom: 32px;
    .div_tit {
      margin-bottom: 16px;
    }
    :deep(p) {
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .item {
      display: flex;
      gap: 16px;
      &:last-child {
        min-height: unset;
      }
      &:last-child :deep(.collapse_card){
          padding-bottom: 0;
      }
      &:not(:last-child) {
        .collapse_schedule {
          @media (min-width: 768px) {
            padding-bottom: 45px;
          }
        }
      }
    }
    .dt1 {
      color: #9096A2;
      white-space: nowrap;
      min-width: 115px;
    }
    .dt2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      .dot {
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
        border-radius: 50%;
        border: 3px solid #ED850A;
      }
      .line {
        content: '';
        width: 2px;
        height: calc(100% + 2px);
        margin-top: -1px;
        background-color: #ED850A;
      }
    }
    .dt3 {
      width: 100%;
    }
  }
  .about_film_list {
    display: flex;
    flex-direction: column;
    gap: 56px;
  }
  .about_film {
    display: flex;
    gap: 4rem;
    &:not(:last-child) {
      border-bottom: 1px solid #6B7280;
      padding-bottom: 18px;
    }
    > .dt1 {
      width: 25.2rem;
      flex: none;
      .dt2{
        display: none;
      }
    }
    .p_16 {
      margin-bottom: 16px;
    }
    .div_text {
      margin-bottom: 24px;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      > .dt1 {
        width: 100%;
        .dt2{
          display: flex;
        }
      }
    }
  }
  .film_tit {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .film_tag {
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ED870A;
    border-radius: 100%;
    font-size: 14px;
    flex: none;
  }
  .features {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .name {
      color: #6B7280;
      font-size: 14px;
    }
    a {
      color: #ED850A;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  .row.gallery {
    gap: 12px 0;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 16px;
    > div {
      padding: 0 6px;
    }
    @media (max-width: 767px) {
      > div:not(:first-child) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .img_gal {
    position: relative;
    height: 0;
    padding-top: 76.8%;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
    &:not(.start) img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.start {
      border-radius: 9px;
      padding: 0;
      height: auto;
      img {
        width: 100%;
      }
    }
    &.end {
      border: 1px solid #6B7280;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .num {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      font-size: 32px;
      font-weight: 500;
      line-height: 150%;
      z-index: 2;
    }
  }
  .schedule_events {
    position: relative;
    //border-top: 1px solid #6B7280;
    margin-bottom: 16px;
    h4 {
      text-transform: uppercase;
    }
    .title {
      padding: 16px 0 8px;
    }
    .subtitle {
      //padding: 8px 0;
      margin: 0;
      //color: rgba(255, 255, 255, 0.5);
    }
    .card_event {
      display: flex;
      padding: 16px 0 32px 0;
      gap: 24px;
    }
    .cards_event {
      &:not(:last-child) {
        border-bottom: 1px solid #6B7280;
      }
    }
    .logo {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dt2 {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .div_desc {
      color: rgba(255, 255, 255, 0.5);
    }
    .list_time {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
    }
    .item {
      padding: 12px 18px;
      border-radius: 30px;
      border: 1px solid #2E3138;
      cursor: pointer;
      &.active {
        border-color: #ED850A;
        background-color: #ED850A;
      }
    }
  }
  .partners {
    .div_tit {
      margin-bottom: 12px;
    }
  }
  @media (max-width: 991px) {
    padding-top: 140px;
  }
  @media (max-width: 767px) {
    padding-top: 68px;
    .afisha_tags {
      .div_tag {
        font-size: 14px;
        padding: 8px 16px;
      }
    }
    .div_description {
      h3, h4 {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .afisha_schedule {
      .div_tit {
        font-size: 24px;
        font-weight: 500;
        line-height: 133.33%;
      }
      .item, .item:last-child {
        position: relative;
        flex-direction: column;
        //justify-content: center;
        gap: 0;
        padding-left: 32px;
        //min-height: 88px;
      }
      .item:last-child {
        .dt3 {
          margin-bottom: 0;
        }
      }
      .dt1 {
        padding-top: 0;
        margin-top: -1px;
      }
      .dt2 {
        position: absolute;
        left: 0;
        height: 100%;
        width: 24px;
        .dot {
          position: absolute;
          top: -1px;
          //top: 50%;
          //translate: 0 -50%;
          left: 0;
          z-index: 1;
          background-color: #000106;
        }
        .line {
          position: absolute;
          left: 50%;
          translate: -50% 0;
          height: 100%;
        }
      }
      .dt3 {
        margin-bottom: 28px;
      }
      :deep(.collapse_card) {
        font-size: 14px;
      }
    }
    /*.afisha_schedule :deep(.collapse_btn){
      &:after {
        display: none;
      }
    }
    .afisha_schedule :deep(.collapse){
      display: none;
    }*/
    .about_film {
      h4 {
        font-size: 24px;
        font-weight: 500;
        line-height: 133.33%;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .dt2.dt2_mob{
        .film_tit{
          display: none;
        }
      }
    }
  }
}
.afisha_calendar {
  margin-bottom: 2.4rem;
}
.ya-map-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;
  border-radius: 8px;
  overflow: hidden;
}
.ya-map-afisha {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
.afisha_aside, .partnersSndOrga {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  .property_title {
    font-size: 18px;
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .div_img {
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-bottom: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .item_property {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
}
.clW20 {
  color: rgba(255, 255, 255, 0.5);
}
.btn_reg {
  width: 100%;
  .btn {
    width: 100%;
  }
}
.disable-reg {
  width: 100%;
  .btn {
    margin-bottom: 8px;
  }
  .div_text {
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: #6B7280;
    letter-spacing: 0.036rem;
  }
  .btn-click {
    pointer-events: auto;
  }
}
.speakers {
  .item {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .div_img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 0;
    flex: none;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .not_img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ED850A;
      color: #fff;
      font-size: 21px;
      line-height: 24px;
      letter-spacing: 0.72px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .div_name {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
  }
  .div_desc {
    font-size: 12px;
    line-height: 14px;
    color: #9096A2;
  }
}
.btnRegSticky {
  display: none;
}
.not_active_seance {
  pointer-events: none;
  background-color: #2E3138;
  border: none;
  color: #000106;
}
.not_active_seance2 {
  pointer-events: none;
}
@media (max-width: 767px) {
  .div_top {
    display: flex;
    flex-direction: column;
    .div_img {
      order: 0;
    }
    .afisha_tit, .afisha_tags, #infoAside, .btnRegFirstPos {
      order: 1;
    }
  }
  .afisha_tit {
    text-transform: unset;
    margin-bottom: 16px;
  }
  .afisha_tags {
    margin-bottom: 32px;
  }
  .btnRegFirstPos {
    width: 100%;
  }
  .btnRegSticky {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 16px 30px;
    background-color: #000106;
    z-index: 5;
    transform: translate(0, 100%);
    transition: transform 250ms ease;
    &.active {
      transform: translate(0, 0);
    }
    .disable-reg {
      .btn {
        margin: 0;
      }
    }
    .btn_reg {
      margin: 0;
      .div_text {
        margin-top: 5px;
        text-align: center;
      }
    }
  }
  .btn_reg {
    margin-bottom: 16px;
  }
  .afisha_aside {
    margin-bottom: 32px;
    gap: 16px;
  }
  .afisha_card {
    .schedule_events {
      .title, .subtitle {
        text-transform: unset;
      }
      .card_event {
        flex-direction: column;
      }
    }
  }
  .speakers {
    margin-bottom: 32px;
  }
  .partners {
    .div_tit {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #FFF;
    }
  }
}
.not_events_info {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>

<style lang="scss" scoped>
.afisha_card_row {
  margin-left: -34px;
  margin-right: -34px;
  :deep(p) {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  > div {
    padding: 0 34px;
  }
  @media (max-width: 991px) {
    margin-left: -12px;
    margin-right: -12px;
    > div {
      padding: 0 12px;
    }
  }
}
@media (min-width: 768px) {

  .afisha_aside {
    position: sticky;
    top: 120px;
    transition: top 0.3s ease-in-out;
  }
  body:has(header.is-hide) {
    .afisha_aside {
      top: 20px;
    }
  }
}

@media (max-width: 767px) {
  .hideMob {
    display: none;
  }
}
</style>

<style lang="scss">
.swiper.sliderThumbsThumbs {
  .swiper-wrapper{
    justify-content: center;
    gap: 1.2rem;
  }
  &.swiper_left{
    .swiper-wrapper {
      justify-content: unset;
    }
  }
}
</style>
